<template>
  <section v-if="payload.download.length || payload.link" class="einfacher-button text-center py-8">
    <template v-if="payload.download.length">
      <a
        v-for="download in payload.download"
        :key="'download-' + download.id"
        :href="download.link"
        class="btn hover-primary"
        target="_blank"
        download
        v-text="payload.title"
      />
    </template>
    <Go
      v-else
      :to="payload.link"
      class="btn hover-primary"
      v-text="payload.title"
    />
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>
